<template>
  <div>
    <div class='mb8'>
      <!--      <el-link @click='handleQuery'>-->
      <!--        <span class='title'>需求明细表</span>-->
      <!--      </el-link>-->
      <!--      &nbsp;-->
      <!--      &nbsp;-->
      <el-form size='mini' inline>
        <el-form-item>
          <el-input placeholder='对接人' v-model='searchCondition.picker' clearable @change='handleQuery'></el-input>
        </el-form-item>
        <el-form-item>
          <el-select placeholder='选择反馈状态' v-model='searchCondition.is_feed' clearable @change='handleQuery'>
            <el-option v-for='(item ,index) in isFeedMap'
                       :key='index'
                       :label='item.label'
                       :value='item.val'>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-input v-model='searchCondition.title' clearable placeholder='反向产品名称搜索'
                    @change='handleQuery'></el-input>
        </el-form-item>
        <el-form-item>
          <el-button icon='el-icon-search' type='primary' @click='handleQuery' :loading='loadingStatus'>查询</el-button>
        </el-form-item>
      </el-form>
      <el-row :gutter='10'>
        <el-col :span='1.5'>
          <el-button icon='el-icon-plus' type='primary' @click='handleAdd'
                     v-if='userPermissions.indexOf("lb_kol_demand_add")>-1'>添加需求
          </el-button>
        </el-col>
        <el-col :span='1.5'>
          <el-button icon='el-icon-upload' type='primary' @click='handleImport'
                     v-if='userPermissions.indexOf("lb_kol_demand_add")>-1'>导入
          </el-button>
        </el-col>
        <el-col :span='1.5'>
          <el-button icon='el-icon-upload' type='danger' @click='handleBatchDel'
                     v-if='userPermissions.indexOf("lb_kol_demand_add")>-1' :disabled='multiple'>批量删除
          </el-button>
        </el-col>
        <el-col :span='1.5'>
          <export-btn :can-export='userPermissions.indexOf("lb_kol_demand_export")>-1' lintTitle='导出'
                      ref='refExportLink'
                      @export='exportDemandExcel("refExportLink")'></export-btn>
        </el-col>
        <el-col :span='1.5'>
          <el-button icon='el-icon-document' @click='showDemandLog'
                     v-if='userPermissions.indexOf("lb_kol_demand_add")>-1'>日志
          </el-button>
        </el-col>
      </el-row>
    </div>
    <div class='default-table'>
      <el-table :data='dataList' highlight-current-row max-height='500' row-key='id'
                @selection-change='handleSelectRows' border>
        <el-table-column type='selection' :reserve-selection='true' width='55'></el-table-column>
        <el-table-column label='序号' type='index' align='center' min-width='60'></el-table-column>
        <!--        <el-table-column label='ID' prop='id' align='center' min-width='100'></el-table-column>-->
        <el-table-column label='类目' prop='category_name' align='center' min-width='100'></el-table-column>
        <el-table-column label='二级类目' prop='category_2_name' align='center' min-width='100'></el-table-column>
        <el-table-column label='反向产品名称' align='center'  min-width='200'>
          <template slot-scope='{row}'>
            {{ row.demand }} {{ row.title }}
          </template>
        </el-table-column>
        <!--        <el-table-column label='需求' prop='demand' align='center' min-width='180'></el-table-column>-->
        <!--        <el-table-column label='反向产品名称' prop='title' align='center' min-width='200'>-->
        <!--          <template slot='header'>-->
        <!--            <div>反向产品名称</div>-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <el-table-column label='产品图片' prop='title' align='center' width='100'>
          <template v-slot='{row:{pic_url}}'>
            <el-image :src='pic_url' style='width: 60px;height: 60px;' width='60px' fit='scale-down'
                      :preview-src-list='[pic_url]'>
              <div slot='error' class='image-slot' style='display: flex;align-items: center;justify-content: center;'>
                <i class='el-icon-picture-outline'></i>
              </div>
            </el-image>

          </template>
        </el-table-column>
        <el-table-column label='运营备注' prop='remark' align='center' min-width='200'></el-table-column>
        <el-table-column label='招商对接人' prop='picker' align='center' min-width='140'>
          <editable-cell v-if='userPermissions.indexOf("lb_kol_demand_picker")>-1' slot-scope='{row,column}'
                         :show-input='row.editMode'
                         maxlength='100' placeholder='最多100个字符'
                         v-model='row[column.property]' :is-selected='false'
                         @change='editCellVal(row,column.property)' :is-input-number='false'>
                                    <span slot='content'>
                                      <span>{{ row[column.property] }}</span>
                                      <i class='el-icon-edit default-edit-icon'></i>
                                    </span>
          </editable-cell>
        </el-table-column>

        <el-table-column label='招商反馈' prop='is_feed' align='center' min-width='480'>
          <el-table-column label='反馈状态' prop='is_feed' align='center' min-width='260'>
            <template slot-scope='{row,column}'>
              <el-radio-group v-model='row.is_feed' @change='editCellVal(row,column.property)' size='mini'>
                <el-radio :label='""'>未设置</el-radio>
                <el-radio :label='`Y`'>已反馈</el-radio>
                <el-radio :label='`N`'>未反馈</el-radio>
              </el-radio-group>
            </template>
          </el-table-column>
          <el-table-column label='反馈内容' prop='feedback' align='center' min-width='200'>
            <editable-cell v-if='userPermissions.indexOf("lb_kol_demand_feedback")>-1' slot-scope='{row,column}'
                           :show-input='row.editMode'
                           maxlength='100' placeholder='最多200个字符'
                           v-model='row[column.property]' :is-selected='false'
                           @change='editCellVal(row,column.property)' :is-input-number='false'>
                                    <span slot='content'>
                                      <span>{{ row[column.property] }}</span>
                                      <i class='el-icon-edit default-edit-icon'></i>
                                    </span>
            </editable-cell>
          </el-table-column>
        </el-table-column>

        <el-table-column label='创建时间' prop='created_at' align='center' show-overflow-tooltip
                         min-width='100'></el-table-column>
        <!--        <el-table-column label='修改时间' prop='updated_at' align='center' min-width='130'></el-table-column>-->
        <el-table-column label='操作' align='center' min-width='120' fixed='right'>
          <template slot-scope='{row}' v-if='userPermissions.indexOf("lb_kol_demand_add")>-1'>
            <el-button type='text' @click='handleEdit(row)'>修改 <span class='el-icon-edit-outline'></span></el-button>
            &nbsp;
            <el-dropdown>
              <span class='el-dropdown-link'>
                更多<i class='el-icon-arrow-down el-icon--right'></i>
              </span>
              <el-dropdown-menu slot='dropdown'>
                <el-dropdown-item>
                  <el-button type='text' icon='el-icon-copy-document' @click='handleCopy(row)'>复制</el-button>
                </el-dropdown-item>
                <el-dropdown-item divided>
                  <el-button type='text' icon='el-icon-delete' @click='handleDelete(row)'>删除</el-button>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <div>
        <!--    渲染分页-->
        <m-pagination :total.sync='total' :limit.sync='pagingData.page_size' :page.sync='pagingData.current_page'
                      @pagination='getList' />
      </div>
    </div>
    <!--    新增或修改-->
    <el-dialog :visible.sync='open' @close='handleQuery' center width='800px' :title='dialogTitle'>
      <div style='width: 700px;margin: auto;'>
        <el-form size='small' :model='formData' ref='form' label-width='120px' label-position='right'
                 class='demand-form'>
          <el-form-item label='类目' required>
            <el-cascader
              style='width: 300px'
              placeholder='选择类目'
              v-model='formData.category_ids'
              :props='cascaderProps'
              @change='cascaderChange'
              :options='categoryList'
              show-all-levels
              ref='cascades'
              clearable
              filterable>
            </el-cascader>
          </el-form-item>
          <el-form-item label='反向产品名称' class='form-item-all-line' :required="formData.category_name !== '秒杀'">
            <el-input v-model='formData.title' type='textarea' :rows='2'
                      placeholder='若有指定产品直接填写产品名，若无，请填写具体功效需求' maxlength='500' show-word-limit
                      clearable></el-input>
          </el-form-item>
          <!--          <el-form-item label='反向产品名称' style='grid-column-start: 1;grid-column-end: 3;' :required="formData.category_name !== '秒杀'">-->
          <!--            <el-input v-model='formData.title' placeholder='若有指定产品直接填写产品名，若无，请填写具体功效需求' maxlength='200' show-word-limit-->
          <!--                      type='textarea' :rows='5'-->
          <!--                      clearable />-->
          <!--          </el-form-item>-->
          <el-form-item label='图片' :required="formData.category_name === '秒杀'">
            <ml-upload-image :key='key' :id.sync='formData.pic_id' :url.sync='formData.pic_url' />
          </el-form-item>
          <el-form-item label='运营备注' class='form-item-all-line'>
            <el-input v-model='formData.remark' :autosize='autosize' type='textarea' placeholder='运营备注'
                      maxlength='500'
                      show-word-limit
                      clearable></el-input>
          </el-form-item>
          <el-form-item style='width:600px;text-align: center'>
            <el-button type='primary' @click.stop.native='saveDemand'>保存</el-button>
            <el-button @click='open=false'>取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <ImportLbDemand :id='lbId' :visible.sync='openImport' @imported='handleQuery' />

    <!--    日志-->
    <el-dialog :visible.sync='logOpen' center width='1200px' title='需求明细日志'>
      <LogList ref='refLog' :info-id='lbId' :op-type="logType" v-if='lbId'/>
    </el-dialog>
  </div>
</template>

<script>
import ExportBtn from '@/components/export/ExportBtn'
import { mapGetters } from 'vuex'
import ImportLbDemand from '@/pages/lb/kol/ImportLbDemand'
import EditableCell from '@/components/EditableCell'
import MlUploadImage from '../../../components/MlUploadImg/single.vue'
import CategoryCascader from '@/pages/category/components/CategoryCascader'
import LogList from '@/pages/lb/components/LogList.vue'


const ModelForm = {
  lb_id: null,
  category_name: null,
  category_2_name: null,
  category_2_id: null,
  category_id: null,
  category_ids: null,
  // demand: null,
  title: null,
  remark: null,
  pic_id: null,
  pic_url: null

}
const getKey = function() {
  return new Date().getTime() + ''
}
export default {
  name: 'DemandItemList',
  components: { LogList, MlUploadImage, EditableCell, ImportLbDemand, ExportBtn, CategoryCascader },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo']),
    cascaderProps() {
      return { value: 'id', label: 'display_name' }
    }
  },
  props: {
    lbId: {
      type: [Number, String],
      default() {
        return null
      }
    }
  },
  watch: {
    lbId: {
      immediate: true,
      handler() {
        this.getList()
      }
    },
    'formData.pic_url'() {
      this.key = getKey()
    }
  },
  data() {
    return {
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      loadingStatus: false,
      searchCondition: {
        lb_id: null,
        //是否反馈
        is_feed: '',
        //招商对接人
        picker: null,
        title: null
      },
      isFeedMap: [
        { label: '已反馈', val: 'Y' },
        { label: '未反馈', val: 'N' }
      ],
      total: 0,
      pagingData: {
        page_size: 10,
        current_page: 1
      },
      dataList: [],
      orderSort: { id: 'asc' },
      formData: { ...{}, ...ModelForm },
      dialogTitle: '添加需求',
      open: false,
      openImport: false,
      autosize: { minRows: 5, maxRows: 10 },
      key: getKey(),
      selectedList: [],
      categoryList: [],
      logOpen: false,
      // 日志类型, 用 "," 分隔
      logType: 'demand_import,demand_export,demand_save,demand_delete',
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    async editCellVal(row, field) {
      if (row.id && field) {
        await this.$api.cellSaveLbScheduleDemandItemInfo({ id: row.id, field: field, val: row[field] })
      } else {
        this.$notify.warning('参数错误，无法提交！！！')
      }
    },
    // async savePicker(row) {
    //   let info = await this.$api.saveLbScheduleDemandItem({ id: row.id, picker: row.picker })
    //   if (info) {
    //     this.$notify.success('对接人数据已录入')
    //   }
    // },
    // async saveFeedStatus(row) {
    //   let info = await this.$api.saveLbScheduleDemandItem({ id: row.id, is_feed: row.is_feed })
    //   if (info) {
    //     this.$notify.success('反馈状态提交成功')
    //   }
    // },
    saveDemand() {
      if (!this.formData.category_id) {
        this.$message.error('请选择类目')
        return false
      }
      if (this.formData.category_name !== '秒杀' && !this.formData.title) {
        this.$message.error('需求必填，若有指定产品直接填写产品名，若无，请填写具体功效需求')
        return false
      }
      if (this.formData.category_name === '秒杀' && !this.formData.pic_url) {
        this.$message.error('秒杀必须上传图片')
        return false
      }
      this.formSubmit()
    },
    async formSubmit() {
      const params = { ...{}, ...this.formData }
      params.lb_id = this.lbId
      let info = await this.$api.saveLbScheduleDemandItem(params)
      if (info) {
        this.open = false
        await this.getList()
      }
    },
    reset() {
      this.formData = { ...{}, ...ModelForm }
    },
    handleAdd() {
      this.reset()
      // this.formData.lb_id = this.lbId
      this.dialogTitle = '添加选品需求明细'
      this.open = true
    },
    handleImport() {
      this.openImport = true
    },
    handleCopy(row) {
      delete row.id
      delete row.is_feed
      delete row.feedback
      delete row.picker
      delete row.created_at
      delete row.updated_at


      this.formData = { ...row }
      this.dialogTitle = '添加选品需求明细'
      this.open = true
    },
    handleEdit(row) {
      this.dialogTitle = '修改选品需求明细'
      row.category_ids = []
      if (row.category_id) {
        row.category_ids = [row.category_id]
        if (row.category_2_id) {
          row.category_ids = [row.category_id, row.category_2_id]
        }
      }

      this.formData = { ...row }
      this.open = true
    },
    handleDelete(row) {
      if (row.id) {
        this.$confirm(`确认删除需求【${row.title || '-'}】?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          let data = await this.$api.delLbScheduleDemandItem(row.id)
          if (data) {
            await this.getList()
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
      } else
        this.$notify.error('删除对象ID为空')
    },
    handleSearchCondition() {
      let condition = {}
      //排期条件必传
      condition['lb_id'] = this.lbId
      Object.keys(this.searchCondition).forEach((field) => {
        if (this.searchCondition[field]) {
          condition[field] = this.searchCondition[field]
        }
      })
      return condition
    },
    handleQuery() {
      this.pagingData.current_page = 1
      this.getList()
    },
    async getList() {
      this.loadingStatus = true
      let searchCondition = this.handleSearchCondition()
      // 合并
      Object.assign(searchCondition, this.pagingData, { order: this.orderSort })
      let { list, pages } = await this.$api.getLbScheduleDemandItemList(searchCondition)
      this.$nextTick(() => {
        this.dataList = list
        this.total = pages.total
        this.offset = pages.offset
        this.pagingData.current_page = pages.current_page
        this.pagingData.page_size = pages.page_size
        this.loadingStatus = false
      })
    },
    async exportDemandExcel(refExportLink) {
      try {
        let searchCondition = this.handleSearchCondition()
        let name = `红人选品需求明细表`
        let response = await this.$api.exportLbScheduleDemandItem(searchCondition)
        if (this.$refs[refExportLink])
          this.$refs[refExportLink].exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
      }
    },
    handleSelectRows(selection) {
      // this.selectedList = selection || []
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    handleBatchDel() {
      this.$confirm(`确认删除【${this.ids.length}条需求数据】?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let data = await this.$api.delBatchLbScheduleDemandItem(this.ids, this.lbId)
        if (data) {
          await this.getList()
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    async loadCategory() {
      // 获取分类列表
      let { list } = await this.$api.getCategoryListByIdentify(`product-category`)
      this.categoryList = list || []
    },
    cascaderChange() {
      // let name = String(this.$refs['cascades'].getCheckedNodes()[0].pathLabels)
      // console.log(this.$refs['cascades'].getCheckedNodes()[0].pathLabels)
      const categoryNames = this.$refs['cascades'].getCheckedNodes()[0].pathLabels
      if (categoryNames[0]) {
        this.formData.category_name = categoryNames[0]
        this.formData.category_id = this.formData.category_ids[0]
      }
      if (categoryNames[1]) {
        this.formData.category_2_name = categoryNames[1]
        this.formData.category_2_id = this.formData.category_ids[1]
      }
      // console.log(this.formData)

      // this.formData.category_name = name.replace(',', ' / ')
    },
    async showDemandLog() {
      // let { list, pages } = await this.$api.getLbScheduleLog({info_id: this.lbId})
      // this.$nextTick(() => {
      //   this.dataList = list || []
      // })
      // console.log(list)
      // console.log(pages)
      this.logOpen = true
    }
  },
  mounted() {
    this.loadCategory()
  }
}
</script>

<style scoped>
.title {
  font-size: 16px;
  font-weight: bold;
}
</style>
<style lang='scss'>
.demand-form {
  display: grid;
  grid-template-columns: repeat(3, calc(33.3% - 10px));
  grid-column-gap: 20px;

  .form-item-all-line {
    grid-column-start: 1;
    grid-column-end: 4;
  }

  .ml-upload-image {
    width: 110px;
    height: 110px;
  }
}

.image-slot {
  height: 100%;
  width: 100%;

  background-color: #f5f7fa;
  color: #c0c4cc;
}
</style>
