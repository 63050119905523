<template>
  <div>
    <el-row :gutter='15'>
      <el-col :xs="24" :sm="24" :md="24" :lg="4" :xl="4">
        <div v-if='showHistoryView'>
          <el-table :data='demandImportList' :cell-class-name='headerRowClass' @row-click='showDemandList'
                    max-height='500'>
            <el-table-column type='index' label='序号' width='50'></el-table-column>
            <el-table-column prop='upload_file' label='上传文件' width='300'></el-table-column>
            <el-table-column prop='created_at' label='上传时间' width='140'></el-table-column>
          </el-table>
          <el-row>
            <el-col :span='24' style='display: flex'>
              <el-button @click='geLbDemandList' type='text' icon='el-icon-refresh'>刷新</el-button>

              <!--    渲染分页-->
              <m-pagination style='display: inline' :total.sync='total' :limit.sync='pagingData.page_size'
                            :page.sync='pagingData.current_page'
                            @pagination='geLbDemandList' layout='total, prev, pager, next' />
            </el-col>
          </el-row>
        </div>
      </el-col>
      <el-col  :xs="24" :sm="24" :md="24" :lg="20" :xl="20">
        <div>
          <el-table :data='demandList' max-height='500' border>
            <el-table-column label='序号' type='index' align='center' width='60'></el-table-column>
            <el-table-column label='类目' prop='category' align='center' width='100'></el-table-column>
            <el-table-column label='需求' prop='demand' align='center' width='140'></el-table-column>
            <el-table-column label='价格带一' align='center' width='200'>
              <el-table-column label='价格带一' prop='price_first' align='center'></el-table-column>
              <el-table-column label='数量' prop='num_first' align='center'></el-table-column>
            </el-table-column>
            <el-table-column label='价格带二' align='center' width='200'>
              <el-table-column label='价格带二' prop='price_second' align='center'></el-table-column>
              <el-table-column label='数量' prop='num_second' align='center'></el-table-column>
            </el-table-column>
            <el-table-column label='价格带三' align='center' width='200'>
              <el-table-column label='价格带三' prop='price_third' align='center'></el-table-column>
              <el-table-column label='数量' prop='num_third' align='center'></el-table-column>
            </el-table-column>
            <el-table-column label="反向产品名称" prop='title' align='center' width='200'>
              <template slot='header'>
                <div>反向产品名称</div>
<!--                <div>（如有定向产品）</div>-->
              </template>
            </el-table-column>
            <el-table-column label='备注' prop='remark' align='center' width='200'></el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'LbDemandList',
  props: {
    LbId: {
      type: [Number, String]
    }
  },
  data() {
    return {
      demandImportList: [],
      demandList: [],
      showHistoryView: false,
      pagingData: {
        page_size: 1,
        current_page: 1
      },
      total: 0,
      orderSort: { id: 'desc' }
    }
  },
  methods: {
    showHistory() {
      this.showHistoryView = !this.showHistoryView
      this.pagingData.current_page = 1
      this.pagingData.page_size = this.showHistoryView ? 10 : 1
      this.geLbDemandList()
    },
    async geLbDemandList() {
      let searchCondition = { 'lb_id': this.LbId }
      Object.assign(searchCondition, this.pagingData, { order: this.orderSort })

      let { list, pages } = await this.$api.getLbScheduleDemandList(searchCondition)
      this.total = pages.total
      this.pagingData.current_page = pages.current_page
      this.pagingData.page_size = pages.page_size
      this.$nextTick(() => {
        this.demandImportList = list

        this.showLastImport()
      })
    },
    showLastImport() {
      if (this.demandImportList.length > 0) {
        let row = this.demandImportList[0]
        if (row.file_txt) {
          this.demandList = JSON.parse(row.file_txt)
        } else {
          this.demandList = []
        }
      }
    },
    // eslint-disable-next-line no-unused-vars
    showDemandList(row, column, event) {
      this.demandList = []
      if (row.file_txt) {
        setTimeout(() => {
          this.demandList = JSON.parse(row.file_txt)
        }, 200)
      }
    },
    // eslint-disable-next-line no-unused-vars
    headerRowClass({ row, rowIndex }) {
      return 'tableRowStyle'
    }
  },
  mounted() {
    this.geLbDemandList()
  }
}
</script>

<style>
.tableRowStyle {
  cursor: pointer;
}
</style>