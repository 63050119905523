<template>
  <div>
    <el-row style="margin: 10px 0;">
      <el-card class='box-card'>
        <div style="position: absolute">
          <el-link type="primary" style="font-size: 18px"  @click="handleRouter"> [服饰]</el-link>
          <el-link  style="font-size: 18px" disabled> [美护]</el-link>
        </div>
        <div style='width: 100%;text-align: center;font-size: 24px;font-weight: bold'>
<!--          <el-link type="primary" style="font-size: 24px" title="点击打开[服饰]" @click="handleRouter"> [美护]</el-link>-->
          {{ detailTitle || '' }} 红人选品详情 <label
            :style='`font-size:14px;text-align: center;border-radius: 5px;padding:2px 8px;margin:1px;border: #7d8086 1px solid;background-color:${progressStatusColorMap[info.progress_status]}`'>{{
            progressStatusAliasMap[info.progress_status] || '--'
          }}</label>
        </div>
      </el-card>
    </el-row>
    <div class='main-page-content'>
      <div style='padding: 5px 0'>
        <div v-if='id&&userPermissions.indexOf("lb_kol_info")>-1' style='padding-top: 10px'>
          <el-descriptions title='基本信息'>
            <template slot='title'>
              基本信息
              <el-link type='primary' icon='el-icon-edit-outline' @click='handleEdit(info)' :disabled='!canEdit'
                       v-if='userPermissions.indexOf("lb_kol_edit")>-1'
                       style='margin-left: 4px;font-size: 1.1em'></el-link>
            </template>
            <el-descriptions-item label='红人昵称'>{{ info.nickname }}</el-descriptions-item>
            <el-descriptions-item label='直播平台'>{{ info.platform_name }}<span style="color: #E6A23C">
              （平台ID：{{ info.platform_account_id || '/'}} ）
            </span></el-descriptions-item>

            <el-descriptions-item label='选品主题'>
              {{ info.theme||'' }}
            </el-descriptions-item>
            <el-descriptions-item label='选品时间'>
              {{ $utils.parseTime(info.start_at, '{y}年{m}月{d}日') }}
            </el-descriptions-item>
            <el-descriptions-item label='直播时间'>{{ $utils.parseTime(info.live_time, '{y}年{m}月{d}日') }}
            </el-descriptions-item>
            <el-descriptions-item label='已有全案品'>{{ info.pit_num }}</el-descriptions-item>
            <el-descriptions-item label='直播项目负责人'>{{ info.leader_name }}</el-descriptions-item>
            <el-descriptions-item label='统筹负责人'>{{ info.overall_name }}</el-descriptions-item>
            <el-descriptions-item label='备注'>{{ info.remark }}</el-descriptions-item>
          </el-descriptions>
        </div>
        <!--        需求明细-->
        <div v-if='userPermissions.indexOf("lb_kol_demand")>-1'>
          <el-divider content-position='left'>需求明细表</el-divider>
          <div>
            <DemandItemList :lb-id='id' v-if='userPermissions.indexOf("lb_kol_demand_list")>-1' />
          </div>
        </div>

        <div style='width: 100%;text-align: center;margin-top: 50px'>
          <el-button :type='`${progress_status > 1?"success":"primary"}`' @click='commitDemand' size='medium'
                     icon='el-icon-s-promotion' plain
                     style='width: 200px'
                     :disabled='!canEdit'
                     v-if="userPermissions.indexOf('lb_kol_demand_launch')>-1">
            {{ progress_status > 1 ? '已发起需求' : '发起需求' }}
          </el-button>
        </div>
      </div>
      <!--      选品列表-->
      <div style='margin-top: 10px'>
        <el-divider content-position='left'>选品列表</el-divider>
        <!--      红人选品页面-->
        <SelectionKol :id='id' v-if='userPermissions.indexOf("lb_kol_selection_list")>-1&&id' :show-header='false' :detail-title='detailTitle' />
      </div>
      <!--      操作日-->
      <div v-if='userPermissions.indexOf("lb_kol_log")>-1'>
        <el-divider content-position='left'>操作日志</el-divider>
        <div >
          <LogList ref='refLog' :info-id='id' v-if='id'/>
        </div>
      </div>

      <div style='margin-top: 10px' v-if='userPermissions.indexOf("lb_kol_delete")>-1'>
        <el-divider content-position='left'>请谨慎操作</el-divider>
        <el-button size='small' plain icon='el-icon-delete' type='danger' @click='handleDelete(info)' :disabled='!canEdit'>删除排期</el-button>
      </div>
    </div>

    <!--    创建直播排期-->
    <CreateEdit :visible.sync='open' :id='id' @close='getInfo' />

    <!--    发起需求-->
    <CommitDemandConfirm :visible.sync='openDemandConfirm' :id='id' @close='getInfo'/>
  </div>
</template>

<script>


import CreateEdit from './CreateEdit'
import LbScheduleLog from '@/pages/lb/LbScheduleLog'
import LogList from '@/pages/lb/components/LogList'
import { mapGetters } from 'vuex'
import ExportBtn from '@/components/export/ExportBtn'
import LbDemandList from '@/pages/lb/components/LbDemandList'
import CommitDemandConfirm from '@/pages/lb/components/CommitDemandConfirm'
import SelectionAckCard from '@/pages/lb/selection_ack/SelectionAckCard'
import SelectionKol from '@/pages/lb/kol/SelectionKol'
import DemandItemList from '@/pages/lb/kol/DemandItemList'

export default {
  name: 'Detail',
  components: {
    DemandItemList,
    SelectionKol,
    SelectionAckCard,
    CommitDemandConfirm,
    LbDemandList,
    ExportBtn,
    LogList,
    LbScheduleLog,
    CreateEdit
  },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo']),
    canEdit() {
      return (this.userPermissions.indexOf('lb_schedule_edit_leader') > -1 || this.info.leader_id === this.userInfo.id)
    },
    progressStatusMap() {
      let map = {}
      this.progressStatusOptions.forEach((item) => {
        map[item.value] = item.class_name
      })
      return map
    },
    progressStatusAliasMap() {
      let map = {}
      this.progressStatusOptions.forEach((item) => {
        map[item.value] = item.label
      })
      return map
    },
    progressStatusColorMap() {
      let map = {}
      this.progressStatusOptions.forEach((item) => {
        map[item.value] = item.color_value
      })
      return map
    },
    detailTitle() {
      return this.info.id ? `${this.$utils.parseTime(this.info.start_at, '{y}年{m}月{d}日')}【${this.info.nickname}】${this.info.platform_name}` : ''
    }
  },
  data() {
    return {
      id: null,
      info: {},
      dataList: [],
      isTable: false,
      progress_status: 1,
      open: false,
      openImport: false,
      openDemandConfirm: false,
      progressStatusOptions: [
        { label: '新创建', value: 1, color_value: '#FFFFFF', class_name: 'status-new' },
        { label: '已发需求', value: 2, color_value: '#67C23A', class_name: 'status-launch' },
        { label: '初选完成', value: 3, color_value: '#409EFF', class_name: 'status-original' },
        { label: '确认完成', value: 4, color_value: '#E6A23C', class_name: 'status-ack' },
        { label: '锁定表格', value: 5, color_value: '#F56C6C', class_name: 'status-lock' }
      ],
      showSelectionCard: false
    }
  },
  methods: {
    init() {
      this.getInfo()
    },
    async getInfo() {
      let { info } = await this.$api.getLbScheduleInfo(this.id)
      this.info = info
      // console.log(this.info)
      this.progress_status = info.progress_status
      this.$nextTick(() => {
        this.dataList = [info]
        document.title=`【${info.nickname}】红人选品`
        // console.log(this.dataList)
      })
    },
    handleEdit() {
      this.open = true
    },
    async commitDemand() {
      this.openDemandConfirm = true
      // await this.$api.launchLbSchedule(this.id)
      // await this.init()
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const id = row.id
      this.$confirm(`确认【删除】排期（${this.$utils.parseTime(row.start_at, '{y}-{m}-{d}')}【${row.nickname}】${row.platform_name}）及选品？ 注：删除后可在回收站恢复`, '警告', {
        confirmButtonText: '确定删除',
        cancelButtonText: '再想想',
        type: 'warning'
      }).then(() => {
        this.delInfo(id)
      }).catch(() => {
        this.msgWarn('取消操作')
      })
    },
    async delInfo(id) {
      let info = await this.$api.delLbSchedule(id)
      if (info) {
        this.msgSuccess('删除成功')
        // this.$msgbox.alert("删除成功","操作结果")
        this.$router.push({
          name: "LbScheduleKol",
        })
      }
    },
    handleRouter(){
      let params = {
        id: this.id,
        nickname: this.info.nickname,
        date: this.info.date,
        platform_name: this.info.platform_name
      }

      const { href } = this.$router.resolve({
        name: 'productDressKol',
        params: params
      })
      window.open(href, '_blank')
    }
  },
  mounted() {
  },
  created() {
    this.id = this.$route.params.id || this.$route.query.id
    this.getInfo()
  }
}
</script>

<style scoped>
.title {
  font-size: 16px;
  font-weight: bold;
}

.box-progress {
  width: 90%;
  padding: 10px;
  height: 100px;
  /*line-height: 100px;*/
  border: 1px solid #000c17;
  border-radius: 5px;
  /*margin-bottom: 10px;*/
  line-height: 100px;
}

.box-progress-status {
  border: #1e1e33 1px solid;
  border-radius: 5px;
  min-width: 10% !important;
  width: 20%;
  max-width: 20% !important;
  line-height: 10px;
}

.required-dot {
  color: #ff0020;
  margin-right: 5px;
}

.link {
  cursor: pointer;
}

.box-padding {
  padding: 3px 5px 1px;
  color: black;
}

.status-new {
  background-color: #FFFFFF;
  color: #000c17;
}

.status-launch {
  background-color: #67C23A;
  color: #fefefe;
}

.status-original {
  background-color: #409EFF;
  color: #fefefe;
}

.status-ack {
  background-color: #E6A23C;
  color: #fefefe;
}

.status-lock {
  background-color: #F56C6C;
  color: #fefefe;
}
</style>
